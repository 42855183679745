<template>
    <div class="page">
        <div class="conter w-1350">
            <div class="conter-left">
                <div class="hzycenter-text">
                    <p></p>
                    <span>国家分区及排列</span>
                </div> 
                <!-- <div class="nav-list">
                    <div class="nav-item">
                        <div>中亚</div>
                        <p><i class="el-icon-arrow-right"></i></p>                         
                    </div>
                </div> -->
                <div class="nav-list">
                    <div class="nav-item" v-for="(item,index) in stateList" :key="index" @click="tabClick(item.id)">
                        <div :class="{active:id==item.id}">{{item.name}}</div>
                        <p><i class="el-icon-arrow-right"></i></p>
                    </div>
                </div>
            </div>

            <div class="conter-right">
                <div class="hzynav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{path: '/hwzy'}">海外资源</el-breadcrumb-item>
                        <el-breadcrumb-item>国家分区及排列</el-breadcrumb-item>
                        <!-- <el-breadcrumb-item>{{title}}</el-breadcrumb-item> -->
                    </el-breadcrumb>
                </div>
                <div class="details-wrap">
                    <div class="details">
                        <div class="details-text" v-if="stateList.length>0">{{data.name}}</div> 
                        <div class="details-img" v-if="stateList.length>0">
                            <img :src="data.banner_url" alt="">
                        </div>
                    </div>
                    <!-- <div class="details-list">
                        <div class="details-p">南亚下属国家列表<span>（点击国家名称，进入对应电商平台列表）:</span></div>
                        <div class="details-item-wrap">
                            <div class="details-item" @click="goTo('/ziyuan_list')">
                                <img src="../../assets/images/qizi.png" alt="">
                                <p>印度</p>
                            </div>
                        </div>
                    </div> -->
                    <div class="details-list">
                        <div class="details-p" v-if="stateList.length>0">{{data.name}}下属国家列表<span>（点击国家名称，进入对应电商平台列表）:</span></div>
                        <div class="details-item-wrap" v-if="stateList.length>0">
                            <div class="details-item" v-for="(item,index) in data.cs" :key="index" @click="goTo('/ziyuan_list',{p_id:item.p_id,id:item.id})">
                                <img :src="item.banner_url" alt="">
                                <p>{{item.name}}</p>
                            </div> 
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
        
    </div>
</template>

<script>
    import {getcountrys} from '../../api/index';
    export default {
        data() {
            return {
                stateLoading:false,
                id:0,
                stateList:[],
                data:{}
            }
        },
        created() {
            this.id=this.$route.query.id
            // console.log(this.key)
            this.getcountrys()            
        },
        modules(){},
        methods: {    
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            getcountrys(){
                // this.stateLoading=true;
                getcountrys().then((res)=>{
                    setTimeout(() => {
                        this.stateLoading=false;
                    }, 500);
                    console.log(res)
                    if (res.code==200) {
                        this.stateList=res.data
                        if(!this.$route.query.id){
                            this.id = res.data[0].id;                            
                        }
                        this.setdata()
                        
                    }
                })
            },
            tabClick(id){
                this.id=id;
                this.setdata()
                
            },
            setdata(){
                this.stateList.forEach(v => {
                    if(this.id == v.id){
                        this.data = v
                    }                    
                });
            }
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{height:25px;display: flex;align-items: center;}
    .hzycenter-text{
        height:25px;display: flex;align-items: center;
        p{width: 3px;height: 25px;background: #0071DB;}
        span{font-size: 18px;color: #0071DB;margin-left: 10px;}
    }
    .conter{
        margin: auto;margin-bottom:140px;margin-top: 45px;display: flex;
        .conter-left{
            width:230px;flex-shrink: 0;margin-right: 15px;
            .nav-list{
                border: 1px solid #CDCDCD;margin-top:15px;
                .nav-item{cursor: pointer;
                    height: 50px;padding: 0 20px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #CDCDCD;
                    div{font-size: 16px;color: #3D3D3D;}
                    p{color: #0071DB;font-size: 22px;font-weight: bold;}
                    &:hover{
                        div{color: #0071DB;}
                    }
                    &:last-of-type{border-bottom: none;}
                }
                .active{color: #0071DB !important;}

            }
        }
        .conter-right{
            flex: 1;
            .details-wrap{
                background: #fff;margin-top: 15px;padding: 50px 0;
                .details{
                    text-align: center;overflow: hidden;
                    .details-text{color: #555555;font-size: 28px;}
                    .details-img{width: 35%;margin:50px auto;
                        img{width: 100%;}
                    }
                }
                .details-list{
                    margin: 20px;margin-bottom:60px;
                    .details-p{
                        color: #555555;font-size: 18px;margin-left:15px;
                        span{font-size: 16px;}
                    }
                    .details-item-wrap{
                        display: flex;flex-direction: row;flex-wrap: wrap;
                        .details-item{
                            width: 11.46%;text-align:center; padding:0 15px;margin-top:40px;cursor: pointer;
                            img{width: 100%;margin: auto;}
                            p{margin-top: 15px;font-size: 16px;}
                            &:hover{
                                p{color: #FE6D00;text-decoration:underline;}
                            }
                        }
                    }

                }
            }
        }
        
    }

</style>